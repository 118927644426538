.tabButtons {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 10px;
  }
  
  .tabButton {
    padding: 10px 20px;
    margin-right: 5px;
    border-radius: 25px;
    background-color: #f1f1f1;
    border: 1px solid #ccc;
    cursor: pointer;
  }
  
  .tabButton:hover {
    background-color: #ac326c;
    color: #fff;
  }
  
  .active {
    background-color: #ac326c;
    color: white;
    border: 1px solid #ac326c;
  }
  
  .tabContent {
    padding: 20px;
    border: 1px solid #ccc;
    background-color: #fff;
  }
  