@font-face {
    font-family: 'Comfortaa Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Comfortaa Regular'), url('../src/assest/fonts/Comfortaa-Regular.woff') format('woff');
}
  @font-face {
    font-family: 'Comfortaa Light';
    font-style: normal;
    font-weight: normal;
    src: local('Comfortaa Light'), url('../src/assest/fonts/Comfortaa-Light.woff') format('woff');
}
@font-face {
    font-family: 'Comfortaa Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Comfortaa Bold'), url('../src/assest/fonts/Comfortaa-Bold.woff') format('woff');
}
@font-face {
  font-family: 'Comfortaa Medium';
  font-style: normal;
  font-weight: normal;
  src: local('Comfortaa Medium'), url('../src/assest/fonts/Comfortaa-Medium.woff') format('woff');
}
@font-face {
  font-family: 'Vintage Brush Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Vintage Brush Regular'), url('../src/assest/fonts/VintageBrush.woff') format('woff');
}
@font-face {
  font-family: 'Lato Thin';
  font-style: normal;
  font-weight: normal;
  src: local('Lato-Thin'), url('../src/assest/fonts/Lato-Thin.woff') format('woff');
}
@font-face {
  font-family: 'Lato Light';
  font-style: normal;
  font-weight: normal;
  src: local('Lato-Light'), url('../src/assest/fonts/Lato-Light.woff') format('woff');
}
@font-face {
  font-family: 'Lato Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Lato-Regular'), url('../src/assest/fonts/Lato-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Lato Medium';
  font-style: normal;
  font-weight: normal;
  src: local('Lato-Medium'), url('../src/assest/fonts/Lato-Medium.woff') format('woff');
}
@font-face {
  font-family: 'Lato Semibold';
  font-style: normal;
  font-weight: normal;
  src: local('Lato-Semibold'), url('../src/assest/fonts/Lato-Semibold.woff') format('woff');
}
@font-face {
  font-family: 'Lato Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Lato-Bold'), url('../src/assest/fonts/Lato-Bold.woff') format('woff');
}
@font-face {
  font-family: 'Lato Black';
  font-style: normal;
  font-weight: normal;
  src: local('Lato-Black'), url('../src/assest/fonts/Lato-Black.woff') format('woff');
}

body {
  margin: 0;
  font-family: 'Lato Regular', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fff;
  font-size: 18px;
  color: #000000;
  line-height: 29px;
}
@media only screen and (min-width:1599px){
.container{max-width: 1400px;}
}
ul{margin: 0;padding: 0;}
li{list-style-type: none;}
a{text-decoration: none;}
button{margin: 0;border: none;font-family: 'Lato Bold';}
figure{margin: 0;padding: 0;}

/* Common Heading CSS */
h1{font-family: 'Comfortaa Bold';font-size: 92px;line-height: 98px;color: #303537;margin-bottom: 30px;}
h2{font-family: 'Comfortaa Medium';font-size: 84px;line-height: 83px;letter-spacing: 8px;}
h3{font-family: 'Vintage Brush Regular';font-size: 65px}
h3 span{font-family: 'Comfortaa Medium';letter-spacing: 2px;color: #000;}
h4{font-family: 'Comfortaa Regular';font-size: 50px;}
h5{font-family: 'Lato Bold';font-size: 25px;}
h6{font-family: 'Comfortaa Medium';}
/* Common Heading CSS */
.noFixHeader{position: fixed;}
.pageLoader{position: fixed;top: 0;left: 0;width: 100%;height: 100vh;background: #fff;display: flex;align-items: center;justify-content: center;z-index: 9999;}

.watchOwl *{transition: all .2s linear;}
.watchOwl .slick-slide{padding: 0 25px;}
.watchOwl .slick-slide.slick-current .itemList{position: relative;opacity: 1;height: 100%;border-radius: 10px;}
 .watchOwl .slick-slide.slick-current .itemList:after{position: absolute;content: "";top: 0;left: 0;width: 100%;height: 100%;background: linear-gradient(270deg, rgba(38, 29, 29, 0) 0%, #261D1D 100%);} 
 .watchOwl .slick-slide figure{position: relative;z-index: 9;}
.watchOwl .slick-slide.slick-current .itemList>div{display: block;}
.watchOwl .slick-slide.slick-current .itemList button{display: flex;flex-direction: column;align-items: center;justify-content: center;}
.watchOwl .slick-slide .itemList{opacity: .3;height: 400px;overflow: hidden;}
.watchOwl .slick-slide .itemList button{display: none;}
.watchOwl .slick-slide .itemList figure img{width: 100%;height: 100%;object-fit: cover;}
.arrowButton .slick-prev{left: 0;}
.arrowButton .slick-next{right: 0;}
.arrowButton .slick-arrow{width: 57px;height: 57px;background: #00000041!important;border-radius: 50%;z-index: 9;}
.arrowButton .slick-arrow:hover{background: #00000090!important;}
.arrowButton .slick-arrow:after{content: none;}
.arrowButton .slick-arrow:before{content: "";background: url('../src/assest/images/button_arrow.webp');width: 24px;height: 16px;left: 50%;top: 50%;transform: translate(-50%, -50%);position: absolute;}
.arrowButton .slick-arrow.slick-prev{transform: rotate(-180deg);left: 15%;}
.arrowButton .slick-arrow.slick-next{right: 15%;}


.blackArrow .react-multiple-carousel__arrow{width: 57px;height: 57px;background: transparent;border: 1px solid #000;}
.blackArrow .react-multiple-carousel__arrow::before{content: "";background: url('../src/assest/images/button_arrow.webp');width: 24px;height: 16px;left: 50%;top: 50%;transform: translate(-50%, -50%);position: absolute;filter: invert(100%);}
.blackArrow .react-multiple-carousel__arrow:hover{background: #000;}
.blackArrow .react-multiple-carousel__arrow:hover:before{filter: invert(0%);}
.blackArrow .react-multiple-carousel__arrow--left{transform: rotate(-180deg);left: 0;}
.blackArrow .react-multiple-carousel__arrow--right{right: 0;}

@media only screen and (max-width:1399px){
 body{font-size: 15px;line-height: 25px;}   
 h1{font-size: 75px;line-height: 82px;}
 h2{font-size: 60px;line-height: 59px;}
 h3{font-size: 55px}
 h4{font-size: 40px;}
 h5{font-size: 20px;}
}

@media only screen and (max-width:1199px){
body{font-size: 14px;line-height: 24px;}   
h1{font-size: 60px;line-height: 72px;}
h2{font-size: 45px;line-height: 46px;}
h3{font-size: 40px}
h4{font-size: 35px;}
h5{font-size: 18px;}
.arrowButton .slick-arrow, .blackArrow .react-multiple-carousel__arrow{width: 40px;height: 40px;}
.arrowButton .slick-arrow:before, .blackArrow .react-multiple-carousel__arrow::before{width: 18px;height: 12px;background-size: contain;}
.watchOwl .slick-slide .itemList{height: 330px;}
.arrowButton .slick-arrow.slick-prev{left: 7%;}
.arrowButton .slick-arrow.slick-next{right: 7%;}
}

@media only screen and (max-width:991px){
h1{font-size: 45px;line-height: 55px;}  
h2{font-size: 40px;line-height: 41px;}
h3{font-size: 32px}
h4{font-size: 28px;}
.watchOwl .slick-slide .itemList {height: 280px;}
.watchOwl .slick-slide{padding: 0 15px;}
}

@media only screen and (max-width:767px){
h2{font-size: 32px;line-height: 31px;}
h4{font-size: 24px;}
.arrowButton .slick-arrow, .blackArrow .react-multiple-carousel__arrow{width: 30px;height: 30px;    min-width: 30px;min-height: 30px;}
.arrowButton .slick-arrow:before, .blackArrow .react-multiple-carousel__arrow::before{width: 14px;height: 9px;background-size: contain;}
}