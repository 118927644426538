footer{position: relative;background: #F9F9F9;}
footer:after{position: absolute;top: 0;left: 0;width: 100%;height: 4px;content: "";background: url('../../assest/images/footer_border.png') ;}
.topFooter{padding: 60px 0;}
.leftSide{display: flex;justify-content: space-between;padding-right: 80px;}
.footerLink h5{font-size: 21px;color: #08638A;margin-bottom: 15px;}
.footerLink ul li{font-size: 16px;color: #000;margin-bottom: 5px;}
.footerLink ul li a{color: #000;}
.rightSIde{position: relative;padding: 0 70px 0 0;}
.rightSIde .flexFooter{display: flex;justify-content: space-between;}
.social{display: flex;margin-bottom: 10px;}
.social li{margin-right: 10px;}
.withAnanta{display: flex;flex-direction: column;}
.withAnanta a{display: flex;align-items: center;background: #41BDDA;margin-right: 15px;color: #fff;padding: 12px 65px 12px 26px;border-radius: 100px;position: relative;margin-bottom: 10px;}
.withAnanta a .fIcon{position: absolute;top: 20px;right: 20px;font-size: 24px;}
.withAnanta a p{margin: 0 0 0 10px;font-size: 13px;line-height: 20px;}    
.withAnanta a p span{font-family: 'Lato Bold';font-size: 20px;display: block;}    
.withAnanta a:last-child{background: #C62C80;padding: 12px 80px 12px 26px;}
.withAnanta a:last-child p span{font-size: 15px;}
.copyRightSec{position: relative;padding: 30px 0;border-top: 1px solid #F1E4E4;}
.copyFlex{display: flex;align-items: center;justify-content: space-between;}
.copyFlex p{margin: 0;font-size: 16px;}
.copyFlex p a{color: #000;}

@media only screen and (max-width:1399px){
.leftSide img{width: 200px;}
.footerLink h5{font-size: 18px;}
.footerLink ul li, .copyFlex p{font-size: 14px;}
.rightSIde{padding: 0 30px 0 0;}
}

@media only screen and (max-width:1199px){
.leftSide{padding-right: 40px;}
.rightSIde{padding: 0 0 0 0;}
.withAnanta a p span{font-size: 16px;}
.withAnanta a{padding: 12px 45px 12px 20px;}
.withAnanta a:last-child{padding: 12px 45px 12px 20px;}
.withAnanta a p{font-size: 11px;}
.withAnanta a .fIcon{right: 10px;}
.copyRightSec{padding: 15px 0;}
.fLogo img{width: 150px;}
}

@media only screen and (max-width:991px){
.leftSide{padding-right: 0;}
.withAnanta a{width: 200px;}
}

@media only screen and (max-width:767px){
.footerLink{margin-bottom: 20px;}
.topFooter{padding: 40px 0 20px;}
.copyFlex{flex-direction: column;}
.footerLink ul li, .copyFlex p{line-height: 19px;}
footer:after{background-size: contain;}
}

@media only screen and (max-width:580px){
.rightSIde .flexFooter{flex-direction: column;}
.withAnanta a{width: 210px;}
}