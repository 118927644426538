header{padding: 0 0 10px;z-index: 999;top: 0;left: 0;width: 100%;background: #fff;}
header.fixHeader{padding: 0;position: fixed;width: 100%;left: 0;transition: all .5s ease;}
.logo img{transition: all .5s ease;width: 132px;height: auto;}
header.fixHeader .logo img{width: 100px;height: auto;}
header.fixHeader .navBar li a{font-size: 16px;}
.flexHeader{display: flex;justify-content: space-between;align-items: center;}
.mainMenu{display: flex;}
.navBar {display: flex;align-items: center;}
.navBar li a{color: #000;padding: 0 30px;border-right: 1px solid #C62C7F;transition: all .5s ease;}
.navBar li a:hover, .navBar li.active a{color: #BF076A;}
.navBar li:last-child a{border: none;}
.contactBtn{position: relative;}
.contactBtn button{color: #fff;font-size: 14px;background: #5EC1DE;padding: 13px 20px;border-radius: 100px;position: relative;}
.contactBtn svg{margin-left: 8px;}
.contactBtn ul{position: absolute;top: 60px;right: 10px;border-bottom-left-radius: 20px;border-bottom-right-radius: 20px;overflow: hidden;width: 142px;padding-top: 10px;z-index: 9;}
.contactBtn ul:after{    position: absolute;content: "";top: 0;right: 10px;border-bottom: 10px solid #BF076A;border-left: 6px solid transparent;border-right: 6px solid transparent;}
.contactBtn ul li a{font-size: 14px;padding: 8px 12px;border-bottom: 1px dotted #A8075E;color: #fff;display: block;text-align: left;background: #BF076A;}
.contactBtn ul li:last-child a{border: none;}
.mobToggle, .closeToggle{display: none;}
@media only screen and (max-width:1599px){
    .navBar li a{padding: 0 22px;}   
}

@media only screen and (max-width:1399px){
.navBar li a{padding: 0 20px;}    
.contactBtn{padding: 10px 15px;}
.contactBtn ul{top: 65px;right: 20px;}
}

@media only screen and (max-width:1199px){
.navBar li a{padding: 0 10px;}
header.fixHeader .navBar li a{padding: 0 10px;font-size: 13px;}
.contactBtn{padding: 10px 0;}
.contactBtn button{padding: 12px 15px;}
}

@media only screen and (max-width:991px){
.mobToggle{display: block;margin: 0;background: #C62C7F;color: #fff;font-size: 20px;opacity: 1;padding: 5px 8px;border-radius: 5px;}
.closeToggle{display: block;position: absolute;top: 10px;right: 10px;background: transparent;color: #fff;font-size: 20px;padding: 0;}
.logo img{width: 100px;}
header.fixHeader .logo img{width: 70px;}
.mainMenu{position: fixed;top: 0;width: 300px;height: 100vh;z-index: 9999;background: #C62C7F;flex-direction: column;padding-top: 30px;transition: all .5s ease;right: -100%;}
.mainMenu.active{right: 0;}
.mainMenu ul{flex-direction: column;align-items: flex-start;}
.mainMenu ul li{width: 100%;}
.mainMenu ul li a{        padding: 8px 15px;font-size: 14px;border-bottom: 1px dotted #fff;color: #fff;display: block;font-family: 'Lato Semibold';color: #fff;}
header.fixHeader .navBar li a{padding: 8px 15px;font-size: 14px;}
.navBar li a:hover{color: #fff;}
.contactBtn{padding: 0;}
.contactBtn button{background: transparent;width: 100%;padding: 8px 15px;display: flex;align-items: center;justify-content: space-between;border-bottom: 1px dotted #fff;border-radius: 0;}
.navBar li:last-child a{border-bottom: 1px dotted #fff;}
.contactBtn ul{width: 100%;top: 41px;border-radius: 0;padding: 0;        right: 0;}
header{padding: 0;}
}