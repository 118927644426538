/* src/Admin/AdminLayout/AdminLayout.module.css */

.adminLayout {
  display: flex;
  height: 100vh; /* Full viewport height */
}

.sidebar {
  width: 250px; /* Fixed width for sidebar */
  background-color: #ac326c; /* Teal background for sidebar */
  color: white; /* White text in sidebar */
 padding: 20px;  
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}
.sidebar ul {
  list-style-type: none; /* Remove bullet points */
  padding: 0; /* Remove default padding */
}

.sidebar ul li {
  margin: 15px 0; /* Space between links */
}

.link {
  color: white; /* Default link color */
  text-decoration: none; /* Remove underline */
  font-size: 18px; /* Font size for links */
  transition: color 0.3s, background-color 0.3s; /* Smooth transition for color and background */
  padding: 5px; /* Space inside link for better click area */
  display: block; /* Makes the entire area clickable */
}

.link:hover {
  color: #fff; /* Gold color on hover */
  background-color: #3ba5c1; /* Light background on hover */
  border-radius: 5px;
}

.activeLink {
  color: #fff; /* Gold color for active link */
  font-weight: bold; /* Bold font for active link */
  background: linear-gradient(270deg,#89a86a,#3ba5c1 , #db623a 61%,#ac326c);
  border: none;
  display: block;
  border-radius: 5px;
  padding: 5px ;
}

.mainContent {
  flex: 1; /* Take remaining space */
  overflow-y: auto; /* Scroll if content overflows */
}

.adminHeader {
  background-color: #fff; /* Rich pink color */
 color: white; /* White text */
  padding: 10px; /* Space around header */
  text-align: center; /* Center text */
  font-size: 18px; 
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Soft shadow */
}

.content {
  padding: 20px; /* Space above the nested routes */
  background-color: #fff;
}
.logout{
  color: #ac326c;
  float: right;
  padding: 20px 30px;
  transform: rotate(270deg);
}
