/* src/Admin/Pages/Login.css */
body {
    margin: 0;
    ; /* Light gray background */
  }
  
  .loginContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;font-family: Arial, sans-serif;
    background-color: #f0f0f0 /* Full viewport height */
  }
  
  .loginForm {
    background: white; /* White background for the form */
    padding: 20px;
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Soft shadow */
    width: 300px; /* Fixed width for the form */
  }
  
  h2 {
    margin-bottom: 20px; /* Space below the heading */
    color: #ac326c; /* Rich pink color */
  }
  
  .formGroup {
    margin-bottom: 15px; /* Space between form groups */
  }
  
  label {
    display: block; /* Block display for labels */
    margin-bottom: 5px; /* Space below labels */
    color: #555; /* Medium text color */
  }
  
  .formInput {
    width: 100%; /* Full width inputs */
    padding: 10px; /* Inner padding */
    border: 1px solid #ccc; /* Light gray border */
    border-radius: 4px; /* Rounded corners for inputs */
    transition: border-color 0.3s; /* Smooth transition */
  }
  
  .formInput:focus {
    border-color: #3ba5c1; /* Teal border on focus */
    outline: none; /* Remove default outline */
  }
  
  .loginButton {
    background-color: #ac326c; /* Rich pink background */
    color: white; /* White text */
    border: none; /* Remove border */
    padding: 10px; /* Inner padding */
    border-radius: 4px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor */
    width: 100%; /* Full width button */
    transition: background-color 0.3s; /* Smooth transition */
  }
  
  .loginButton:hover {
    background-color: #3ba5c1; /* Teal background on hover */
  }
  