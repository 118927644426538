/* Common CSS use */
.centerTitle{text-align: center;}
/* Common Button */
.commonBtn{font-family: 'Lato Bold';font-size: 16px;padding: 10px 35px;border-radius: 100px;}
.commonBtn svg{font-size: 24px;}
.blackBtn{color: #000;border: 1px solid #000;}
.whiteBtn{color: #fff;border: 1px solid #fff;}
.pinkBtn{background: #C62C7F;color: #fff;}
/* Common Button */
/* Inner Banner Image and BreadcrumbStyle */
.innerBanner{position: relative;}
.innerBanner img{width: 100%;height: auto;}
.BreadcrumbStyle{position: relative;padding: 15px 0;}
.BreadcrumbStyle ul{display: flex;align-items: center;}
.BreadcrumbStyle ul li{font-family: 'Lato Bold';font-size: 16px;color: #000;position: relative;padding-right: 25px;}
.BreadcrumbStyle ul li:after{position: absolute;top: 0;right: 10px;content: ">";color: #918C99;font-size: 14px;    font-family: 'Comfortaa Regular';}
.BreadcrumbStyle ul li:last-child::after{content: none;}
.BreadcrumbStyle ul li a{font-family: 'Lato Light';color: #000}
.brownBg .BreadcrumbStyle{background: #F8F8F8;}
/* Inner Banner Image and BreadcrumbStyle */

/* Common CSS use */

.homeBanner{position: relative;background: #F7F7F7;padding: 0 0;overflow: hidden;height: 700px;}
.bannerContainer{position: absolute;top: 0;left: 0;width: 100%;padding: 60px 0;}
.bannerContent{width: 550px;position: relative;z-index: 9;}
.bannerContent p{padding-right: 180px;}
.bannerContent h1 span{display: block;}
.bannerContent p{padding-right: 180px;}
.bannerImg{display: flex;justify-content: flex-end;position: relative;height: 100%;}
.bannerImg figure{position: relative;width: 365px;height: 100%;    border-top-left-radius: 65px 50%;border-bottom-left-radius: 65px 50%;margin-right: -65px;overflow: hidden;}
.bannerImg figure img{width: 100%;height: 100%;object-fit: cover;}
.bannerImg figure:last-child{margin: 0 -65px 0 0;}

.founderBg{background: linear-gradient(180deg, #F2EFEF 0%, #FFFFFF 100%);padding: 100px 0 50px;}
.founderProfile{padding-left: 40px;position: relative;}
.founderProfile button{position: absolute;    bottom: 90px;right: 30px;background: transparent;}
.founderProfile figure img {width: auto;max-width: 100%;height: auto;}
.founderContent{display: flex;flex-direction: column;height: 100%;justify-content: center;padding-right: 30px;}
.founderContent h2{color: #045476;margin-bottom: 40px;}
.founderContent h2 span{display: block;}
.founderContent p{line-height: 30px;margin-bottom: 10px;}
.founderContent .flexBtn{display: flex;margin-top: 40px;}
.founderContent .flexBtn a{font-family: 'Lato Regular';padding: 18px 42px;margin-right: 8px;}
.founderContent .flexBtn a:first-child{background: #41BDDA;}
.socialSec{position: relative;border-top: 1px solid #D2D2D2;padding: 45px;}
.socialSec ul{display: flex;justify-content: center;align-items: flex-end;}
.socialSec ul li{display: flex;flex-direction: column;justify-content: center;align-items: center;padding: 0 30px;}
.socialSec ul li span{font-family: 'Lato Black';font-size: 15px;}


.watchSec{position: relative;background: linear-gradient(102.35deg, #05638B 44.54%, #11759F 83.51%);padding-top: 70px;height: 660px;position: relative;z-index: 1;}
.watchSec h3{color: #F29A13;text-align: center;}
.watchSec h3 span{color: #fff;}
.watchSlider{position: relative;margin-top: 50px;overflow: hidden;}
.videoItem{position: relative;margin: 0 0;}
.videoItem img{width: 100%;height: 100%;object-fit: cover;}
.palyOverLap{position: relative;}
.videoItem .videoContent{position: absolute;top: 0;left: 0;z-index: 9;color: #fff;padding-left: 35px;font-family: 'Comfortaa Regular';width: 100%;height: 100%;padding-top: 60px;padding-right: 200px;z-index: 9;}
.videoItem .videoContent:before{position: absolute;content: "";top: 0;left: 0;width: 100%;height: 100%;background: linear-gradient(270deg, rgba(38, 29, 29, 0) 0%, #261D1D 100%);}
.videoItem .videoContent:after{position: absolute;top: 60px;left: 0;content: "";width: 10px;height: 80px;border-radius: 90px;background: #C62C7F;}
.videoItem .videoContent h4{letter-spacing: 3px;font-size: 30px;}
.videoItem .videoContent *{position: relative;z-index: 9;}
.videoItem .videoContent p{font-size: 16px;margin: 0;letter-spacing: 1px;}
.videoItem button{position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);background: transparent;z-index: 10;}
.videoItem button img{width: auto;height: auto;}
.videoItem button span{color: #fff;font-family: 'Comfortaa Bold';font-size: 15px;margin-top: 3px;}
.videoItem figure>div{width: 100%!important;height: 550px!important;}

.coursesSec{position: relative;background: linear-gradient(180deg, #D9D9D9 0%, #FCFCFC 100%);padding: 210px 0 70px;}
.coursesSec h3{text-align: center;color: #08638A;}
.coursesSec h3 span{color: #000;}
.coursesOwlSlider{position: relative;padding-left: 100px;margin-top: 45px;}
.coursesOwlSlider .courseItem{position: relative;margin: 0 15px;}
.coursesOwlSlider .courseItem figure{height: 420px;}
.coursesOwlSlider .courseItem figure img{width: 100%;height: 100%;object-fit: cover;border-radius: 10px;}
.coursesOwlSlider .courseItem .courseName{display: flex;justify-content: space-between;align-items: center;background: #3DC1E1;padding: 0 25px;border-radius: 10px;margin-top: -30px;position: relative;z-index: 1;height: 110px;}
.courseName h5{margin: 0;color: #fff;}
.courseBtn{text-align: center;padding: 0 15px;margin-top: 50px;display: flex;align-items: center;justify-content: center;}

.webinarsSec{position: relative;background: linear-gradient(180deg, #ECECEC 0%, #FEFEFE 100%);padding: 70px 0;}
.webinarsSec h3{margin-bottom: 50px;text-align: center;}
.webinarsSec h3 span{color: #161616;}
.webContainer{position: relative;display: flex;flex-direction: column;justify-content: center;    align-items: center;}
.webContainer h5{font-family: 'Comfortaa Bold';letter-spacing: 1px;}
.webContainer figure{border: 1px dotted #D1CCCC;padding: 20px;border-radius: 10px;margin-top: 20px;height: 500px;}
.webContainer figure img{width: 100%;max-width: 100%;height: 100%;border-radius: 10px;object-fit: contain;}

.kidsSec{display: flex;position: relative;z-index: 1;}
.kidsSec>div{flex: 1 1 50%;}
.kidsImg img{width: 100%;height: auto;}
.kidsContent{background: linear-gradient(180deg, #035274 0%, #056F9D 100%);display: flex;align-items: center;justify-content: center;flex-direction: column;color: #fff;position: relative;padding: 0 150px;position: relative;}
.kidsContent:after{position: absolute;content: "";top: -60px;left: 50%;transform: translate(-50%);background: url('../../assest/images/flowers_img.png') no-repeat;width: 325px;height: 134px;}
.kidsContent:before{position: absolute;content: "";bottom: -60px;left: 50%;transform: translate(-50%);background: url('../../assest/images/flowers_img.png') no-repeat;width: 325px;height: 134px;}
.kidsContent h3{color: #D0D055;font-size: 65px;text-align: center;line-height: 65px;margin-bottom: 30px;}
.kidsContent h3 span{display: block;color: #fff;}
.kidsContent p{font-size: 32px;line-height: 45px;text-align: center;margin-bottom: 30px;}
.kidsContent p span{display: block;}

.legacySec{position: relative;background: linear-gradient(180deg, #F8FDFF 0%, #E9F0F3 100%); padding: 360px 0 80px;}
.legacySec>div{position: relative;z-index: 9;}
.legacySec:after{position: absolute;content: "";top: 0;left: 0;width: 100%;height: 100%;background: url('../../assest/images/legacy_bg.webp') no-repeat 10% center;}
.legacyContainer{width: 900px;max-width: 100%;margin: 0 auto;text-align: center;}
.legacyContainer h3{color: #08638A;margin: 0;}
.legacyContainer h3 span{color: #000;}
.legacyContainer h2{color:#F2A32A;font-size: 60px;letter-spacing: 2px;line-height: normal;}
.legacyContainer h6{font-size: 23px;}
.legacyOwl{position: relative;margin-top: 30px;}
.legacyOwl .item{margin: 0 100px;overflow: hidden;}
.legacyOwl .item>div{position: relative;background: #fff;border-radius: 10px;padding: 60px 45px;text-align: left;}
.legacyOwl .item h5{font-size: 22px;color: #C62C7F!important;margin: 0;}
.legacyOwl .item h5 p, .legacyOwl .item h5 span{margin: 0;color: #C62C7F!important;}
.legacyOwl .item p{line-height: 35px;font-size: 16px;line-height: 26px;}

.communityec{position: relative;background: linear-gradient(180deg, #4B978A 0%, #00A387 100%);padding: 80px 0;}
.communityec:after{position: absolute;content: "";top: 0;left: 0;width: 100%;height: 100%;background: url('../../assest/images/texture_bg.webp') repeat;background-size: cover;opacity: .4;}
.communityec>div{position: relative;z-index: 1;}
.communityec h3{text-align: center;color: #A9FFA6;width: 425px;max-width: 100%;line-height: 61px;margin: 0 auto 40px;}
.communityec h3 span{color: #fff;}
.communityElem{position: relative;border-radius: 10px;overflow: hidden;}
.communityElem figure{height: 340px;}
.communityElem figure img{width: 100%;height: 100%;object-fit: cover;}
.communityElem:after{position: absolute;content: "";top: 0;left: 0;width: 100%;height: 100%;background: linear-gradient(360deg, #000000 0%, rgba(102, 102, 102, 0) 100%);}
.communityElem .detail{position: absolute;bottom: 30px;left: 0;width: 100%;padding: 0 30px;z-index: 1;}
.communityElem .detail span{font-size: 16px;font-family: 'Lato Bold';color: #FF671D;}
.communityElem .detail h5{font-size: 30px;color: #fff;margin: 0;display: flex;align-items: center;justify-content: space-between;}
.ideasSecBlock{position: relative;padding: 80px 0;}
.ideasSecBlock>div{position: relative;z-index: 9;}
.ideasSecBlock .ideasSec{position: absolute;padding: 80px 0;overflow: hidden;top:0;left: 0;width: 100%;height: 100%;z-index: 0;}
.ideasSec:before{position: absolute;top: 0;left: 0;width: 100%;height: 2000px;background: url('../../assest/images/idea_bg.webp') repeat; animation: bottomTop infinite 30s linear;content: "";}
@keyframes bottomTop {
    from {
      top: 0;
    }
    to {
      top: -100%;
    }
  }
.ideasSec:after{position: absolute;content: "";top: 0;left: 0;width: 100%;height: 100%;background: #000000de;}  
.ideasSecBlock h3{text-align: center;color: #FF671D;}
.ideasSecBlock h3 span{color: #fff;}
.ideasContainer{width: 1265px;max-width: 100%;border-radius: 10px;background: #FFFFFF;padding: 30px;display: flex;align-items: flex-end;justify-content: center;margin: 30px auto 0;}
.ideasContainer .ideasElem{flex: 1 1 50%;display: flex;flex-direction: column;justify-content: center;align-items: center;position: relative;}
.ideasContainer .ideasElem:after{position: absolute;content: "";top: 50%;right: 0;width: 1px;height: 80%;background: #C3B5B5;transform: translate(0, -50%);}
.ideasContainer .ideasElem figure img{width: auto;max-width: 100%;height: auto;}
.ideasContainer .ideasElem button.watchBtn{background: #C62C80;font-size: 16px;padding: 16px 42px;color: #fff;border-radius: 100px;margin-top: -30px;}
.ideasContainer .ideasElem button.watchBtn img{margin-left: 40px;}
.ideasContainer .ideasElem:last-child button{background: #41BDDA;}
.ideasContainer .ideasElem:last-child:after{content: none;}

.aboutBlock{position: relative;padding: 90px 0;background: #F5F5F5;}
.aboutBlock h2{color: #045476;}
.aboutBlock h2 span{display: block;}
.aboutBlockSpace{position: relative;padding: 0 30px;}
.aboutBlock figure{position: relative;}
.aboutBlock figure:after{position: absolute;top: 0;left: 0;content: "";width: 100%;height: 100%;background: rgba(198, 44, 127, 0.2);border-radius: 10px;transform: rotate(-4.62deg);}
.aboutBlock figure img{width: auto;max-width: 100%;height: auto;border-radius: 10px;position: relative;z-index: 1;}
.alignBottom{display: flex;align-items: flex-end;}
.valueBlock{position: relative;background: linear-gradient(180deg, #1C6483 0%, #0479AB 100%);padding: 70px 0;}
.valueBlock h3{color: #F29A13;}
.valueBlock h3 span{color: #fff;}
.valueContainer{position: relative;width: 1210px;max-width: 100%;margin: 50px auto 0;}
.valueElem{position: relative;}
.valueElem img{width: 100%;max-width: 100%;height: auto;filter: drop-shadow(0px 4px 12px rgba(210, 202, 202, 0.25));border-radius: 10px;}
.valueElem h5{position: absolute;bottom: 10px;left: 50%;width: 90%;transform: translate(-50%);background: #fff;border-radius: 10px;margin: 0;font-size: 30px;text-align: center;padding: 25px 0;}
.purposeBlock{background: linear-gradient(180deg, #1C6483 0%, #0479AB 100%);padding: 90px 0 135px;}
.purposeBlock h3{color: #F29A13;}
.purposeBlock h3 span{color: #fff;}
.purposeContainer{position: relative;width: 1082px;max-width: 100%;margin: 50px auto 0;padding: 40px 80px;display: flex;background: #fff;flex-wrap: wrap;}
.purposeContainer .purposeImg{width: 40%;}
.purposeContainer .purposeImg img{width: auto;max-width: 100%;height: auto;}
.purposeContent{width: 60%;padding-left: 0;display: flex;flex-direction: column;justify-content: center;}
.purposeContent ul li{background: url('../../assest/images/star_icon.png') no-repeat top left;padding-left: 35px;padding-bottom: 24px;margin-bottom: 24px;position: relative;}
.purposeContent ul li:after{position: absolute;content: "";bottom: 0;right: 0;width: 94%;height: 1px;border-bottom: 1px dotted #C6C6C6;}
.purposeContent ul li:last-child{border: none;margin: 0;padding-bottom: 0;}
.purposeContent ul li:last-child::after{content: none;}

.blogsBlock{position: relative;background: #fff;padding: 70px 0;}
.blogsBlock h3{color: #C62C7F;}
.blogsBlock h3 span{color: #000;margin-left: 5px;}
.blogsBlock h3{margin-bottom: 50px;}
.blogList{position: relative;margin-bottom: 50px;}
.blogList figure{margin-bottom: 15px;height: 245px;}
.blogList figure img{width: 100%;height: 100%;border-radius: 10px;object-fit: cover;}
.blogList h6{font-size: 18px;line-height: 32px;color: #000;padding: 0 15px;height: 60px;margin-bottom: 10px;}
.blogList h6 a{display: block;}
.blogList h6 a{color: #000;}
.blogList p, .blogContent p{font-size: 16px;line-height: 26px;color: #635B5B;padding: 0 15px;}
.blogContent p br{display: none;}
.blogContent p span, .blogList p span{color: #635B5B!important;}
.blogList .blogTime{padding: 20px 15px 0;border-top: 1px dotted #CEC7C7;display: flex;justify-content: space-between;}
.blogList .blogTime span, .blogContent .postDetail span {font-family: 'Lato Bold';font-size: 16px;color: #635B5B;}
.blogList .blogTime a{background: #08638A;width: 30px;height: 30px;border-radius: 50%;display: flex;align-items: center;justify-content: center;color: #fff;font-size: 26px;}
.blogContent{position: relative;margin-top: 20px;background: #F5F5F5;padding-bottom: 140px;}
.blogDetail{padding-top: 50px;}
.blogDetailImg img{width: 100%;height: auto;} 
.blogContent .contentBox{background: #fff;width: 1115px;max-width: 100%;padding: 50px 70px;margin: -200px auto 0;}
.blogContent h6{font-size: 55px;line-height: 72px;color: #000;margin-bottom: 20px;}
.blogContent h6 span{display: block;}
.blogContent p{padding: 0;}
.postDetail{border-top: 1px dotted #CEC7C7;display: flex;justify-content: space-between;padding-top: 15px;}
.postDetail ul{display: flex;align-items: center;}
.postDetail ul li{margin-left: 5px;}
.postDetail ul li a img{width: 30px;}
.postDetail ul li a.shareBtn img{width: auto;}
.shareBtn{background: #08638A;width: 30px;height: 30px;border-radius: 50%;display: flex;align-items: center;justify-content: center;}

.coursesBlock{position: relative;padding: 80px 0;}
.multiCourse{padding: 0 30px;}
.multiCourse .heading{padding-right: 50px;position: sticky;top: 30px;}
.multiCourse .heading h3{color: #08638A;}
.multiCourse .heading h3 span{display: block;color: #000;}
.allCourseCont{display: flex;flex-wrap: wrap;margin: 0 -18px;}
.allCourseCont .courseItem{width: 50%;padding: 0 18px;position: relative;margin-bottom: 20px;}
.allCourseCont .courseItem figure img{width: 100%;border-radius: 10px;height: auto;}
.allCourseCont .courseName{margin-top: -30px;background: #3DC1E1;display: flex;justify-content: space-between;align-items: center;position: relative;padding: 0 20px;border-radius: 10px;height: 110px;}
.allCourseCont .courseItem:nth-child(2) .courseName{background: #7AC177;}
.allCourseCont .courseItem:nth-child(3) .courseName{background: #10749F;}
.allCourseCont .courseItem:nth-child(4) .courseName{background: #F96D42;}
.allCourseCont .courseItem:nth-child(5) .courseName{background: #C62C7F;}
.coursesDetailBlock{position: relative;padding: 70px 0 130px;}
.coursesDetailBlock:after{position: absolute;content: "";top: 0;left: 0;width: 100%;height: 100%;background: url('../../assest/images/texture_bg1.png') repeat;}
.coursesDetailBlock h3{color: #08638A;}
.coursesDetailBlock h3 span{color: #000;}
.courseDetail{position: relative;z-index: 9;}
.detailContainer{position: relative;background: #fff;padding: 65px 75px;display: flex;flex-wrap: wrap;z-index: 9;margin-top: 20px;}
.detailContainer .imgSide{width: 30%;}
.detailContainer .imgSide img{width: 100%;height: auto;border-radius: 10px;}
.detailContainer .content{width: 70%;padding-left: 40px;display: flex;flex-direction: column;justify-content: center;}
.detailContainer h4{font-family: 'Lato Bold';font-size: 45px;margin-bottom: 15px;}
.detailContainer ul{display: flex;margin-top: 10px;}
.detailContainer ul li{width: 275px;background: #CB418B;border-top-left-radius: 10px;border-top-right-radius: 10px;text-align: center;padding-top: 15px;margin-right: 30px;}
.detailContainer ul li h5{margin: 0;text-align: center;color: #fff;display: flex;flex-direction: column;font-size: 24px;}
.detailContainer ul li h5 span{font-size: 25px;background: #08638A;padding: 20px 0;margin-top: 15px;}
.detailContainer ul li h5 span p{margin: 0;}

.communityBlock{position: relative;background: linear-gradient(180deg, #4B978A 0%, #00A387 100%);padding: 90px 0;}
.communityBlock:before{position: absolute;content: "";top: 0;left: 0;width: 100%;height: 100%;background: url('../../assest/images/texture_bg.webp') repeat;opacity: .4;}
.communityBlock h3{width: 425px;max-width: 100%;color: #A9FFA6;margin: 0 auto;line-height: 65px;}
.communityBlock h3 span{color: #fff;}
.communityBlock>div{position: relative;z-index: 9;}
.communityContainer{position: relative;margin-top: 40px;}
.communityRow{position: relative;background: #fff;display: flex;flex-wrap: wrap;padding: 70px 95px;margin-bottom: 25px;}
.communityRow .imageSide{width: 40%;position: relative;border-radius: 10px;overflow: hidden;}
.communityRow .imageSide .playBtn{position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);content: "";background: transparent;margin: 0;padding: 0;}
.communityRow figure img{width: 100%;height: auto;}
.communityRow .content{width: 60%;padding-left: 50px;display: flex;flex-direction: column;justify-content: center;}
.communityRow .content span{font-family: 'Lato Bold';font-size: 16px;color: #C62C7F;}
.communityRow .content h5{font-size: 30px;margin: 5px 0 15px;}
.communityRow:nth-child(even) .imageSide{order: 2;}
.communityRow:nth-child(even) .content{order: 1;padding: 0 50px 0 0;}
.communityVideo{position: fixed!important;top: 0;left: 0;width: 100%;height: 100%;background: #00000098;z-index: 9999!important;}
.communityVideo .videoCont{position: absolute;top: 50%;left:50%;height: 50%;background: #fff;width: 800px;height: 500px;max-width: 90%;transform: translate(-50%, -50%);max-height: 90vh;}
.communityVideo .closeVideo{    position: absolute;top: 0;right: 0;width: 35px;height: 35px;background: #C62C7F;color: #fff;display: flex;justify-content: center;align-items: center;z-index: 9;font-size: 18px;}

.commingBlock{position: relative;padding: 65px 0;background: #F5F5F5;}
.commingContainer{position: relative;width: 1045px;max-width: 100%;margin: 0 auto;}
.commingContainer h3{color: #08638A;}
.commingContainer h3 span{color: #000;}
.commingElem{position: relative;margin-top: 30px;border-radius: 10px;overflow: hidden;}
.commingElem::before{position: absolute;content: "";top: 0;left: 0;width: 100%;height: 100%;background: linear-gradient(360deg, #000000 0%, rgba(102, 102, 102, 0) 100%);}
.commingElem figure img{width: 100%;height: auto;}
.commingElem .content{position: absolute;bottom: 30px;left: 0;width: 100%;padding: 0 50px;display: flex;justify-content: space-between;align-items: center;}
.commingElem .content h6{font-size: 35px;width: 450px;max-width: 100%;color: #fff;margin: 0;line-height: 45px;}

.recentBlock{position: relative;padding: 70px 0;}
.recentBlock h3{color: #C62C7F;}
.recentBlock h3 span{margin-left: 10px;}
.recentBox{position: relative;width: 1185px;max-width: 100%;margin: 30px auto 0;display: flex;flex-wrap: wrap;}
.recentBox .recentElem{width: 50%;padding: 0 10px;border-radius: 10px;overflow: hidden;position: relative;margin-bottom: 20px;}
.recentBox .recentElem figure{position: relative;border-radius: 10px;overflow: hidden;height: 330px;}
.recentBox .recentElem figure:after{position: absolute;content: "";top: 0;left: 0;width: 100%;height: 100%;background: linear-gradient(360deg, #000000 0%, rgba(102, 102, 102, 0.42) 100%);}
.recentBox .recentElem figure img{width: 100%;height: 100%;object-fit: cover;}
.recentBox .recentElem .content{position: absolute;bottom: 20px;left: 0;width: 100%;padding: 0 30px;display: flex;justify-content: space-between;align-items: center;}
.recentBox .recentElem h6{font-size: 17px;line-height: 25px;color: #fff;margin: 0;}
.recentBox .recentElem .content a{margin-left: 20px;}
.webinarDetail{position: relative;padding: 50px 0 90px;background: linear-gradient(180deg, #FFFFFF 0%, #FEFEFE 100%);}
.webDetailContainer{width: 1185px;max-width: 100%;margin: 0 auto}
.registerNow{padding: 0 30px;display: flex;align-items: center;justify-content: space-between;position: relative;z-index: 9;margin-top: 30px;}
.webTime{display: flex;padding-left: 0;position: relative;z-index: 9;margin-top: 0;}
.webTime li{width: 275px;background: #CB418B;border-top-left-radius: 10px;border-top-right-radius: 10px;margin-right: 15px;color: #fff;text-align: center;font-size: 24px;font-family: 'Lato Bold';    padding-top: 20px;}
.webTime li img{margin-right: 10px;position: relative;top:-5px;}
.webTime li span{background: #08638A;padding: 20px 0;width: 100%;display: block;margin-top: 10px;font-size: 25px;}
.webImg{position: relative;margin-top: -40px;margin-bottom: 50px;}
.webImg .mainImg img{width: 100%;height: auto;}
.webImg .scanerImg{position: absolute;bottom: 20px;right: 50px;background: #fff;border-radius: 10px;padding: 10px;}
.webImg .scanerImg img{width: 155px;height: auto;}
.webDetailContainer p{font-family: 'Comfortaa Medium';font-size: 30px;line-height: 55px;padding: 0 20px;}
.webDetailContainer a{background: #C62C80;font-family: 'Lato Bold';font-size: 15px;color: #fff;padding: 20px 50px 20px 40px;border-radius: 100px;display: inline-block;margin-top: 10px;}
.webDetailContainer a svg{font-size: 25px;}

.videoBlock{position: relative;background: #F5F5F5;padding: 65px 0;}
.videoBlock h3{color: #FF671D;}
.newVideo{width: 1045px;max-width: 100%;margin: 30px auto 0;position: relative;border-radius: 10px;overflow: hidden;height: 330px;}
.videoBlock .newVideo{height: 600px;}
.videoBlock .newVideo figure{height: 100%;}
.newVideo figure{position: relative;border-radius: 10px;overflow: hidden;background: #000;height: 100%;}
.newVideo figure:after{position: absolute;content: "";top: 0;left: 0;width: 100%;height: 100%;background: linear-gradient(270deg, rgba(38, 29, 29, 0) 0%, #261D1D 100%);z-index: 1;}
.newVideo figure img{width: 100%;height: 100%;object-fit: contain;}
.newVideo .content{position: absolute;bottom: 50px;left: 0;color: #fff;z-index: 2;padding: 10px 15px 0 35px;}
.newVideo .content:after{position: absolute;left: 0;top: 0;width: 15px;height: 100%;content: "";background: #C62C7F;border-radius: 92px;}
.newVideo .content h4{font-family: 'Comfortaa Medium';line-height: 35px;font-size: 30px;}
.newVideo .content h6{font-size: 20px;}
.newVideo .playBtn{position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);background: transparent;display: flex;flex-direction: column;justify-content: center;align-items: center;color: #fff;font-family: 'Comfortaa Medium';font-size: 16px;z-index: 2;}
.newVideo .playBtn span{width: 65px;height: 65px;border-radius: 50%;border: 1px solid #fff;display: flex;align-items: center;justify-content: center;font-size: 24px;margin-bottom: 5px;}
.moreVideoBlock{position: relative;padding: 60px 0;}
.moreVideoBlock h3{color: #C62C7F;}
.moreVideoSec{position: relative;padding: 0 80px;display: flex;flex-wrap: wrap;margin: 0;}
.moreVideoSec .newVideo{width: 50%;padding: 0 10px;margin: 20px auto 0;}
.moreVideoSec .newVideo h6{font-size: 17px;line-height: 25px;margin: 0;height: 55px;display: flex;align-items: center;}
.moreVideoSec .newVideo .content{bottom: 20px;padding-right: 120px;}
.moreVideoSec .newVideo .content:after{width: 6px;height: 55px;top: 0;left: 10px;background: #52CAE7;}
.moreVideoSec .newVideo figure:after{background: linear-gradient(360deg, #000000 0%, rgba(102, 102, 102, 0.42) 100%);}
.moreVideoSec .newVideo .playBtn span{width: 54px;height: 54px;background: #00000059;font-size: 20px;}

.bookingBlock{position: relative;padding: 50px 0 0 ;}
.bookContainer{position: relative;margin-top: 30px;}
.bookContainer figure{position: relative;}
.bookContainer figure::before{position: absolute;top: 0;left: 0;width: 100%;height: 100%;content: none;background: linear-gradient(0deg, rgba(0, 0, 0, 0.116), rgba(0, 0, 0, 0.219))}
.bookContainer figure img{width: 100%;height: auto;}
.bookBanner h3{padding: 0 15px;} 

.bookContainer .content{position: absolute;top: 35%;left: 0;width: 100%;transform: translate(0, -50%);color: #fff;text-align: center;}
.bookContainer .content h4{font-size: 40px;margin-bottom: 20px;}
.bookContainer .content p{width: 550px;margin: 0 auto;max-width: 100%;}
.bookForm{background: #F5F5F5;position: relative;padding: 0 0 80px;}
.formContainer{background: #fff;width: 1115px;max-width: 100%;margin: -200px auto 0;padding: 50px;}
.formContainer .group{display: flex;flex-wrap: wrap;}
.formContainer .group .filed{padding: 0 5px;margin-bottom: 13px;flex: 1;}
.errorMsg{font-size: 12px;color: red;}
.formContainer .group input, .formContainer .group select, .formContainer .group textarea{background: #F9F9F9;border-radius: 8px;border: 1px solid #D3D3D3;font-size: 16px;font-family: 'Lato Regular';width: 100%;padding: 25px 35px;height: 75px;}
.formContainer .group input::placeholder{color: #000000;}
.formContainer .group textarea{height: 160px;}
.formContainer button{font-family: 'Lato Bold';color: #fff;border: none;background: #C62C80;padding: 20px 54px;border-radius: 110px;font-size: 15px;}
.formContainer button svg{margin-left: 30px;font-size: 26px;}

.webinarForm{position: relative;display: flex;flex-wrap: wrap;}
.webinarForm .enrollImg{width: 45%;}
.webinarForm .enrollImg img{width: 100%;height: auto;}
.enrollForm{width: 55%;background: linear-gradient(180deg, #035274 0%, #056F9D 100%);padding: 65px 60px 65px 45px;color: #fff;    display: flex;flex-direction: column;justify-content: center;}
.enrollForm h3 span{color: #fff;display: block;}
.enrollForm h5{font-size: 22px;line-height: 45px;}
.enrollForm p{font-family: 'Lato Light';font-size: 16px;line-height: 25px;}
.enrollForm ul{display: flex;align-items: center;margin: 20px 0;}
.enrollForm ul li{margin-right: 40px;}
.enrollForm ul li img{margin-right: 15px;}
.eveningGroup{display: flex;margin: 0 -4px;margin-bottom: 10px;flex-direction: column;padding-right: 100px;}
.eveningGroup .group{flex: 1;padding: 0 4px;margin-bottom: 15px;}
.eveningGroup .group input, .eveningGroup .group textarea{width: 100%;padding: 22px 35px;border-radius: 8px;border: none;background: #fff;font-size: 15px;}
.eveningGroup .group textarea{height: 145px;}
.eveningGroup button{font-family: 'Lato Bold';color: #fff;border: none;background: #C62C80;padding: 20px 54px;border-radius: 110px;font-size: 15px;}
.eveningGroup button svg{margin-left: 30px;font-size: 26px;}
.pagination{display: flex;align-items: center;}
.pagination span{margin: 0 5px;}
.pagination button{font-family: 'Lato Bold';font-size: 16px;background: transparent;background: #034866;color: #fff;margin: 0 2px;width: 40px;height: 40px;border-radius: 5px; line-height: normal;display: flex;align-items: center;justify-content: center;}
.pagination button.pageActive{font-family: 'Lato Medium';background: #08638A;}

.animeText {display: inline-block;opacity: 0;transform: translateY(10px);transition: opacity 0.5s ease, transform 0.5s ease;}
.animeText.fade-in {opacity: 1;transform: translateY(0);}
.topSpace{margin-top: 112px;}
.webinarForm .enrollImg figure{height: 100%;}
.webinarForm .enrollImg figure img{width: 100%;height: 100%;object-fit: cover;}
.BannerSlider{display: none;}
.textureBg{position: relative;}
.coursesBlock.textureBg{background: #fff;}
.coursesBlock.textureBg:after{opacity: .4;}
.textureBg:after{position: absolute;top: 0;left: 0;content: "";width: 100%;height: 100%;background: url('../../assest/images/texture_bg2.png');z-index: 1;}
.textureBg>div{position: relative;z-index: 9;}
.teameBlock{padding: 80px 0;background: linear-gradient(360deg, #F1F1F1 0%, #ECECEC 100%);}
.teameBlock .heading{text-align: center;}
.teameBlock h3{color: #08638A;}

.teamContainer{position: relative;margin-top: 50px;}
.teamElem{position: relative;display: flex;padding: 65px 90px;background: #fff;border-radius: 10px;flex-wrap: wrap;align-items: center;justify-content: space-between;margin-bottom: 30px;overflow: hidden;}
.teamElem figure{width: 330px;height: 330px;border-radius: 50%;overflow: hidden;}
.teamElem .content{width: 60%;padding-left: 30px;}
.teamElem .teamBtn{width: 10%;text-align: right;}
.teamElem .teamBtn button{background: transparent;margin: 0;padding: 0;}
.teamElem figure img{border-radius: 50%;width: 100%;height: 100%;object-fit: cover;}
.teamElem h5{font-size: 34px;color: #000;margin-bottom: 15px;}
.teamElem h6{font-family: 'Lato Bold';font-size: 23px;color: #1B6C7F;margin-bottom: 10px;}
.teamElem h4{font-size: 34px;color: #F97B54;font-family: 'Lato Bold';}
.teamElem:nth-child(even){    flex-direction: row-reverse;}
.teamElem:nth-child(even) .teamBtn{text-align: left;}
.teamElem:nth-child(even) .teamBtn button img{transform: rotate(-90deg);}
.teamElem:nth-child(even) .content{text-align: right;padding-left: 0;padding-right: 30px;}
.teamElem:nth-child(even) figure{text-align: right;}
.teamElem .moreContant{position: absolute;top: -100%;left: 0;width: 100%;height: 100%;background: #125A6B;padding: 65px 90px;transition: all .5s ease;}
.teamElem .moreContant.active{top: 0;}
.teamElem .moreContant p{color: #fff;font-size: 21px;line-height: 28px;}
.teamElem .moreContant button{position: absolute;top: 0;right: 0;width: 35px;height: 35px;background: #C62C7F;color: #fff;display: flex;justify-content: center;align-items: center;z-index: 9;font-size: 18px;}
.mobShow{display: none!important;}

@media only screen and (max-width:1399px){
.teamElem{padding: 35px 50px;}
.teamElem figure{height: 295px;width: 295px;}  
.teamElem h5{font-size: 28px;margin-bottom: 10px;}
.teamElem h6{font-size: 18px;}
.teamElem h4{font-size: 28px;}
.teamElem .moreContant{padding: 35px 50px;}
.teamElem .moreContant p{font-size: 18px;line-height: 26px;}
.teamElem .teamBtn button img{width: 40px;}
.homeBanner{height: 550px;}
.bannerContent{width: 500px;}
.bannerImg figure{width: 300px;}
.bannerContent p{padding-right: 200px;}
.founderBg{padding: 50px 0;}
.founderContent h2{margin-bottom: 30px;}
.founderContent .flexBtn{margin-top: 20px;}
.alignBottom{align-items: center;}
.aboutBlock .content{padding-left: 30px;}
.valueElem h5{font-size: 24px;}
.aboutBlock{padding: 60px 0;}
.purposeBlock{padding: 60px 0 90px;}
.communityRow{padding: 50px;}
.allCourseCont .courseName{height: 80px;}
.detailContainer h4{font-size: 35px;}
.detailContainer ul li{width: 230px;}
.detailContainer ul li h5{font-size: 20px;}
.detailContainer ul li h5 span{font-size: 21px;padding: 15px 0;}
.coursesDetailBlock{padding: 70px 0 100px;}
.blogList h6{font-size: 16px;line-height: 24px;height: 50px;margin-bottom: 5px;}
.blogContent h6{font-size: 40px;line-height: 50px;}
.blogContent{padding-bottom: 100px;}
.commingElem .content h6{font-size: 30px;}
.recentBox .recentElem h6{font-size: 15px;}
.webTime li{width: 245px;font-size: 18px;}
.webTime li img{width: 24px;}
.webTime li span{font-size: 20px;}
.webDetailContainer p{font-size: 24px;line-height: 45px;}
.watchSec{padding-top: 50px;}
.coursesSec{padding: 160px 0 70px;}
.kidsContent h3{font-size: 55px;}
.kidsContent p{font-size: 24px;line-height: 35px;}
.legacySec{padding: 250px 0 70px;}
.legacyOwl .item p{line-height: 26px;}
.videoBlock .newVideo{height: 500px;width: 850px;}
.newVideo{height: 300px;}
.newVideo .content h4{line-height: 30px;font-size: 26px;}
.newVideo .content h6{font-size: 16px;}
.formContainer{    margin: -170px auto 0;}
}

@media only screen and (max-width:1199px){
  .communityElem figure{height: 280px;}  
  .communityec h3{line-height: normal;}
  .webTime li{width: 220px;}
.webDetailContainer p{font-size: 20px;line-height: 35px;}  
.valueElem h5{font-size: 20px;}
.purposeContainer{    margin: 30px auto 0;padding: 20px 40px;}
.communityBlock h3{line-height: normal;}
.communityBlock{padding: 50px 0;}
.communityRow .content h5{font-size: 24px;}
.formContainer{margin: -100px auto 0;padding: 30px;}
.formContainer .group input, .formContainer .group select, .formContainer .group textarea{height: 55px;}
.formContainer .group textarea{height: 100px;}
.formContainer .group input, .formContainer .group select, .formContainer .group textarea{padding: 20px;}
.eveningGroup .group input, .eveningGroup .group textarea{padding: 15px;}
.eveningGroup button, .formContainer button{    padding: 15px 35px;}
.eveningGroup button svg, .formContainer button svg{margin-left: 10px;}
.coursesBlock{padding: 50px 0;}
.multiCourse .heading{padding-right: 0;}
.allCourseCont .courseName{padding: 0 10px;}
.allCourseCont .courseName a img{width: 35px;}
.detailContainer{    margin-top: 30px;padding: 40px;}
.detailContainer h4{font-size: 30px;margin-bottom: 10px;}
.detailContainer ul{margin: 0;}
.detailContainer ul li{width: 200px;}
.detailContainer ul li h5{font-size: 18px;}
.detailContainer ul li h5 span{font-size: 19px;padding: 15px 0;margin-top: 10px;}
.coursesDetailBlock{padding: 70px 0;}
.blogList figure{height: 200px;}
.blogList h6{font-size: 14px;line-height: 22px;margin: 0;}
.pagination button{width: 30px;height: 30px;font-size: 14px;}
.courseBtn{margin-top: 30px;}
.blogContent .contentBox{padding: 40px;}
.blogContent h6{font-size: 30px;line-height: 40px;}
.blogList p, .blogContent p{font-size: 13px;line-height: 21px;}
.blogContent{padding-bottom: 70px;}
.recentBox .recentElem figure{height: 270px;}
.commingBlock, .recentBlock{padding: 60px 0;}
.webImg .scanerImg img{width: 130px;}
.eveningGroup{padding-right: 0;}
.homeBanner{height: 450px;}
.bannerImg figure{width: 260px;border-top-left-radius: 35px 50%;border-bottom-left-radius: 35px 50%;margin-right: -45px;}
.bannerContent{width: 350px;}
.bannerContent p{padding-right: 0;}
.videoItem figure>div{height: 450px!important;}
.watchSec{height: 540px;}
.coursesOwlSlider{padding-left: 50px;}
.coursesOwlSlider .courseItem figure{height: 250px;}
.coursesOwlSlider .courseItem .courseName{height: 80px;padding: 0 15px;}
.coursesOwlSlider .courseItem{margin: 0 10px;}
.webContainer figure{height: 320px;}
.kidsContent:before, .kidsContent:after{width: 195px;height: 94px;background-size: contain;}
.kidsContent:after{top: -40px;}
.kidsContent h3{font-size: 40px;line-height: 40px;}
.kidsContent p{font-size: 18px;line-height: 28px;}
.legacySec:after{background-size: contain;    top: -60px;}
.legacyContainer{width: 750px;}
.legacyContainer h2{font-size: 45px;}
.legacyContainer h6{font-size: 20px;}
.legacyOwl .item>div{padding: 40px 30px;}
.communityElem .detail span{font-size: 14px;}
.communityElem .detail h5{font-size: 20px;}
.communityElem .detail h5 a img{width: 30px;}
.communityElem .detail{padding: 10px;bottom: 10px;}
.communityec{padding: 60px 0;}
.founderProfile button img{width: 50px;}
.videoBlock .newVideo{height: 450px;width: 768px;}
.newVideo{height: 250px;}
.newVideo .content h4{line-height: 26px;font-size: 22px;}
.newVideo .content h6{font-size: 14px;line-height: 22px;height: 30px;}
.moreVideoSec .newVideo .content{padding-right: 15px;}
.moreVideoSec{padding: 0 40px;}
.teamElem{padding: 20px 30px;}
.teamElem figure{height: 260px;width: 260px;}  
.teamElem h5{font-size: 24px;margin-bottom: 10px;}
.teamElem h6{font-size: 16px;}
.teamElem h4{font-size: 24px;}
.teamElem .moreContant{padding: 20px 30px;}
.teamElem .moreContant p{font-size: 16px;line-height: 24px;}
.teameBlock{padding: 60px 0;}
.bookContainer .content h4{font-size: 30px;}
}

@media only screen and (max-width:991px){
.teamElem{padding: 20px;}
.teamElem figure{height: 190px;width: 190px;}  
.teamElem figure img{width: 100%;height: 100%;}
.teamElem h5{font-size: 16px;margin-bottom: 10px;}
.teamElem h6{font-size: 14px;}
.teamElem h4{font-size: 16px;}
.teamElem .moreContant{padding: 20px;}
.teamElem .moreContant p{font-size: 13px;line-height: 19px;margin-bottom: 8px;}  
.teamElem .teamBtn button img{width: 30px;}
.videoBlock .newVideo{height: 400px;}  
.newVideo .content{bottom: 30px;}
.newVideo{height: 200px;}
.newVideo .playBtn{font-size: 14px;}
.newVideo .playBtn span{width: 45px;height: 45px;font-size: 16px;}
.moreVideoSec .newVideo .playBtn span{height: 35px;width: 35px;font-size: 12px;}
.moreVideoSec{padding: 0 0;}  
.communityElem figure{height: 220px;}    
.webImg .scanerImg{right: 20px;padding: 5px;}  
.webImg .scanerImg img{width: 110px;}  
.webTime li{width: 180px;font-size: 14px;    padding-top: 15px;}  
.webTime li img{width: 20px;}
.webTime li span{font-size: 16px;padding: 10px 0;margin-top: 5px;}
.webDetailContainer a{padding: 15px 20px 15px 25px;font-size: 14px;}
.webDetailContainer p{font-size: 16px;line-height: 30px;}  
.webDetailContainer a svg{margin-left: 10px;}
.recentBox .recentElem figure{height: 210px;}  
.commingElem .content h6{font-size: 26px;line-height: 32px;}
.commingElem .content{padding: 0 30px;}
.recentBox .recentElem h6{font-size: 14px;}
.bolgWidth{width: 50%;}
.blogList{margin-bottom: 30px;}
.detailContainer h4{font-size: 26px;margin-bottom: 10px;}  
.aboutBlock .content{padding-left: 15px;}
.valueBlock{padding: 50px 0;}
.valueElem h5{font-size: 16px;padding: 15px 0;}
.purposeBlock{padding: 50px 0 ;}
.linkSpace{padding-left: 20px;}
.BreadcrumbStyle ul li{font-size: 14px;}
.communityRow{padding: 30px;}
.communityRow .content h5{font-size: 22px;}
.formContainer{margin: -80px auto 0;}
.formContainer .group input, .formContainer .group select, .formContainer .group textarea{padding: 15px;font-size: 14px;}
.eveningGroup .group input, .eveningGroup .group textarea{font-size: 14px;}
.formContainer .group textarea, .eveningGroup .group textarea{height: 100px;}
.enrollForm{padding: 30px;}
.multiCourse{padding: 0;}
.allCourseCont .courseName a img{width: 30px;}
.coursesDetailBlock, .blogsBlock{padding: 50px 0;}
.blogContent .contentBox{margin: -120px auto 0;}
.blogContent h6{font-size: 26px;line-height: 35px;}
.webinarDetail{padding: 50px 0;}
.socialSec ul{width: 600px;max-width: 100%;margin: 0 auto;flex-wrap: wrap;}
.socialSec ul li{width: 25%;padding: 0 15px;}
.socialSec ul li img{width: auto;max-width: 100%;}
.socialSec ul li span{margin-top: 10px;font-size: 14px;}
.bannerImg figure{width: 210px;}
.bannerContent{width: 250px;}
.founderContent{padding: 0;}
.founderContent h2{margin-bottom: 10px;font-size: 35px;}
.founderContent p{line-height: 26px;}
.founderContent .flexBtn a{padding: 13px 26px;margin-right: 8px;font-size: 13px;}
.founderProfile button{    bottom: 50px;right: 0px;}
.founderProfile button img{width: 40px;}
.videoItem figure>div{height: 370px!important;}
.watchSec{height: 440px;padding-top: 40px;}
.watchSlider{margin-top: 20px;}
.coursesSec{padding: 100px 0 40px}
.webContainer figure{height: 250px;padding: 10px;}
.webinarsSec{padding: 50px 0;}
.commonBtn{font-size: 14px;padding: 8px 25px;}
.commonBtn svg{position: relative;top: -1px;}
.kidsContent:before, .kidsContent:after{width: 150px;height: 94px;background-size: contain;}
.kidsContent{padding: 0 50px;}
.kidsContent h3{font-size: 32px;line-height: 32px;}
.kidsContent p{font-size: 16px;line-height: 26px;}
.communityElem .detail span{font-size: 13px;}
.communityElem .detail h5{font-size: 16px;}
.communityElem .detail h5 a img{width: 26px;}
.communityec h3{width: 350px;line-height: normal;}
.ideasSecBlock{padding: 50px 0;}
.ideasContainer .ideasElem button.watchBtn{font-size: 14px;padding: 12px 22px;}
.ideasContainer .ideasElem button.watchBtn img{width: 26px;}
.webinarsSec h3{margin-bottom: 30px;}
.topSpace{margin-top: 80px;}
.legacyOwl .item h5{font-size: 20px;}
.bookContainer .content h4{font-size: 26px;}
}

@media only screen and (max-width:767px){
.blogList p, .blogContent p{font-size: 14px;line-height: 22px;}
.blogList h6{height: auto;margin-bottom: 5px;}  
.teamElem{flex-direction: column;justify-content: center;text-align: center;}  
.teamElem:nth-child(even){flex-direction: column;}
.teamElem figure{width: 100%;height: auto;}
.teamElem figure img{width: 200px;height: 200px;}
.teamElem .content{width: 100%;padding-left: 0;margin-top: 10px;}
.teamElem:nth-child(even) figure, .teamElem:nth-child(even) .content, .teamElem:nth-child(even) .teamBtn{text-align: center;}
.teamElem:nth-child(even) .teamBtn button img{transform: rotate(0deg);}
.teamElem .teamBtn{width: 100%;text-align: center;}
.BannerSlider{display: block;}  
  .legacyOwl .item p{font-size: 14px;}  
.newVideo .content h4{line-height: 26px;font-size: 18px;}
.newVideo .content h6{font-size: 12px;line-height: 20px;height: 30px;}  
.moreVideoSec{flex-direction: column;}
.moreVideoSec .newVideo{width: 100%;height: auto;}
.newVideo .playBtn{font-size: 11px;}
.newVideo .playBtn span{margin: 0;}
.communityElem figure{height: auto;}     
.bannerImg{display: none;}  
.BannerSlider figure{width: 100%;height: 450px;}
.BannerSlider figure:after{position: absolute;content: "";top: 0;left: 0;width: 100%;height: 100%;background: #0000003d;}
.BannerSlider figure img{width: 100%;height: 100%;object-fit: cover;}
.bannerContainer{z-index: 99;}
.bannerContainer h1 , .bannerContainer p span{color: #fff!important;}
.founderContent{margin-top: 30px;}
.founderContent h2{line-height: 37px;}
.socialSec{padding: 40px 0 30px;}
.socialSec ul{flex-wrap: wrap;}
.socialSec ul li{width: 50%;margin-bottom: 20px;}
.videoItem .videoContent h4{font-size: 22px;}
.videoItem .videoContent p{font-size: 14px;}
.videoItem .videoContent{padding-right: 10px;}
  .webImg .scanerImg {right: 10px;bottom: 10px;}
.webImg .scanerImg img{width: 80px;}  
.commingBlock, .recentBlock{padding: 50px 0;}  
.recentBox .recentElem{width: 100%;}  
.recentBox .recentElem figure{height: 250px;}  
.commingElem .content, .recentBox .recentElem .content{padding: 0 15px;}  
.commingElem .content a img, .recentBox .recentElem .content a img{width: 30px;height: auto;}
.commingElem .content h6{font-size: 20px;line-height: 26px;width: auto;}  
  .blogContent .contentBox{padding: 20px;}  
  .blogContent h6{font-size: 22px;line-height: 30px;}
.bolgWidth{width: 100%;}  
.formContainer{margin: -80px auto 0;padding: 20px;}  
.webinarForm{flex-direction: column;}
.webinarForm .enrollImg{height: 500px;}
.enrollForm ul{margin-top: 0;}
.webinarForm .enrollImg figure{height: 100%;}
.webinarForm .enrollImg img{width: 100%;height: 100%;object-fit: cover;}
.webinarForm .enrollImg, .enrollForm{width: 100%;}
.aboutBlock figure{width: 300px;margin: 0 auto 40px;}
.aboutBlock .content, .aboutBlockSpace{padding: 0;}
.valueElem{margin-bottom: 20px;}
.purposeContainer{padding: 15px;flex-direction: column;}
.purposeContainer .purposeImg{text-align: center;}
.purposeContainer .purposeImg, .purposeContent{width: 100%;}
.innerBanner{height: 200px;}
.innerBanner img{width: 100%;height: 100%;object-fit: cover;}
.communityRow{flex-direction: column;padding: 15px;}
.communityRow .imageSide{margin-bottom: 20px;}
.communityRow .imageSide, .communityRow .content{width: 100%;padding: 0;}
.communityBlock h3{width: 280px;}
.communityRow:nth-child(even) .content{padding: 0;}
.bookContainer .content{padding: 0 15px;}
.bookContainer figure{height: 250px;}
.bookContainer figure img{width: 100%;height: 100%;object-fit: cover;}
.multiCourse .heading{position: relative;top: 0;margin-bottom: 20px;}
.allCourseCont{margin: 0 -15px;}
.allCourseCont .courseItem{padding: 0 15px;}
.detailContainer{    margin-top: 20px;padding: 20px;flex-direction: column;}
.detailContainer .imgSide{width: 100%;}
.detailContainer .content{width: 100%;padding: 0;margin-top: 20px;}
.detailContainer ul li{width: 50%;}
.recentBox .recentElem{padding: 0;}
.registerNow{padding: 0 15px;flex-direction: column;}
.webImg{margin-bottom: 30px;margin-top: -20px;}
.webDetailContainer a{padding: 12px 20px 12px 25px;font-size: 13px;}
.BreadcrumbStyle ul li{font-size: 13px;line-height: normal;}
.webContainer figure{width: 100%;}
.webContainer{margin-bottom: 30px;}
.kidsSec{flex-direction: column;}
.kidsContent{padding: 80px 200px;}
.legacySec:after{top: -150px;}
.legacySec{padding: 150px 0 70px;}
.legacyContainer h2{font-size: 30px;}
.legacyContainer h6{font-size: 16px;}
.legacyOwl .item{margin: 0 40px}
.legacyOwl .item h5{font-size: 16px;}
.legacyOwl .item p{margin-bottom: 10px;line-height: 24px;}
.legacyOwl .item>div{padding: 20px;}
.communityElem{width: 400px;margin: 0 auto 30px;max-width: 100%;}
.ideasContainer{padding: 20px 20px 0;flex-direction: column;}
.ideasContainer .ideasElem{padding-bottom: 20px;margin-bottom: 20px;flex: 1 1 100%;width: 100%;}
.ideasContainer .ideasElem:after{width: 100%;height: 1px;bottom: 0;top: auto;}
.founderProfile{padding: 0;}
.coursesOwlSlider .courseItem figure{height: 300px;}
.webinarsSec .courseBtn{margin-top: 0;}
.bannerContent{width: 100%;}
.videoItem button img{width: 40px!important;}
.bannerContent p{color: #fff;font-size: 16px;}
.teamElem .moreContant button{width: 25px;height: 25px;}
.teameBlock{padding: 50px 0;}
.teamContainer{margin-top: 30px;}
.legacySec{padding: 150px 0 50px;}
.registerNow a{display: none;}
.mobShow{display: inline-block!important;}
}

@media only screen and (max-width:576px){
  .teamElem .moreContant{padding: 30px 10px 10px;}
  .teamElem .moreContant p{font-size: 12px;}  
  .homeBanner{height: 400px;}
  .BannerSlider figure{width: 100%;height: 400px;}  
.coursesOwlSlider{margin-top: 30px;}  
  .legacyContainer h2{font-size: 26px;}
  .legacyContainer h6{font-size: 14px;}  
  .coursesOwlSlider{padding-left: 0;}  
  .kidsContent{padding: 80px 15px;}  
.recentBox .recentElem figure{height: 200px;}    
.commingElem .content h6{font-size: 16px;line-height: 24px;}    
.blogContent .contentBox{margin: -60px auto 0;} 
.blogContent h6{font-size: 20px;line-height: 28px;margin-bottom: 10px;} 
.blogContent p{margin-bottom: 10px;}
.detailContainer ul li{margin: 0 10px 0 0;}  
.detailContainer ul li h5{font-size: 15px;}
.detailContainer ul li h5 span{font-size: 16px;padding: 10px 0;margin-top: 8px;}
.formContainer .group, .eveningGroup{flex-direction: column;}
.eveningGroup .group{margin-bottom: 13px;}
.eveningGroup{margin: 0;}
.enrollForm{padding: 15px;}
.eveningGroup .group{padding: 0;}
.formContainer{padding: 15px;}
.enrollForm ul{flex-direction: column;    align-items: flex-start;}
.enrollForm ul li{margin-bottom: 10px;}
.allCourseCont{flex-direction: column;}
.allCourseCont .courseItem{width: 100%;}
.webTime li{width: 160px;margin: 0 4px;}
.webDetailContainer p{font-size: 14px;line-height: 24px;padding: 0;}  
.legacySec:after{top: -180px;}

}